const Home = () => {
	return (
		<>
			<div style={{ textAlign: "center" }}>
				<h1>Sorry!</h1>
				<h3>
					We are designing a new and impoved dashboard for all future
					registrations.
				</h3>
				<h5>Until then, email us: Director@SidewinderVA.com</h5>
			</div>
		</>
	);
};

export default Home;
